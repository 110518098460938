import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import {navigate} from "gatsby"

const StyledWrapper = styled.div`
  .align-center {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 15%;
  }

  .timer, .redirect {
    color: rgb(242, 184, 66);
    font-weight: 700;
  }

`

const NotFoundPage = () => {
    const [timer, setTimer] = useState(5);

    useEffect(() => {
        window.setTimeout(() => {
            navigate("/")
        }, 5000);
    }, []);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => setTimer(timer - 1), 1000);
        }
    }, timer);

    return (
        <Layout>
            <StyledWrapper>
                <div className="container my-5 pt-5">
                    <h1>We've recently upgraded our website</h1>
                    <p>This page has moved and we’re redirecting you to our homepage in <span
                        className="timer">{timer} seconds</span></p>
                    <p>If you're not redirected, <a className="redirect" href="/">please click here</a></p>
                </div>
            </StyledWrapper>
        </Layout>
    );
}

export default NotFoundPage
